import React, { useContext, useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Autocomplete, Box, FormControlLabel, FormGroup, InputLabel, OutlinedInput, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import { ArrowBack, CheckBox, LockOpen, Send, Visibility, VisibilityOff } from "@mui/icons-material";
import makeStyles from '@mui/styles/makeStyles';
import { logout, passwordCredentials, websiteViewAuth } from "../Auth/AuthHandler";
import { Link, useHistory } from "react-router-dom";
import Alert from '@mui/material/Alert';
import { APIURL } from "../../Views/Routes";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import GlassSphereContext from "../GlassSphereContext/GlassSphereContext";
import Checkbox from "@mui/material/Checkbox";
import Card from "../Card/Card";


const useStyles = makeStyles((theme) => ({

    root: {
        marginTop: 10,
        width: '100%',
        // background:theme.palette.primary.main
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: "100%"
    },
    buttonSpacing: {
        padding: 20
    },
    inputField: {
        margin: 10
    },
    info: {
        margin: 10,
        // background: theme.palette.info.main,
        // color: theme.palette.secondary.main
    },

    paddingLeft: {
        paddingLeft: 30
    },
    radio: {
        '&$checked': {
            color: '#4B8DF8'
        }
    },
    checked: {}
}));

export default function UserNamePasswordLogin(props) {
    const { nextStep, headerText, session } = props
    const classes = useStyles()
    const history = useHistory();
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [error, setError] = useState(false)
    const [rememberme, setRememberme] = useState(false)
    const isflutter = typeof window.flutter_inappwebview !== 'undefined'
    // const isflutter = true

    const [skipLoginScreen, setSkipLoginScreen] = useState(isflutter)
    const [videoSource, setVideoSource] = useState()
    const [audioSource, setAudioSource] = useState()

    const [videoDeviceList, setVideoDeviceList] = useState([])
    const [audioDeviceList, setAudioDeviceList] = useState([])

    const [showPassword, setShowPassword] = useState(false)


    useEffect(() => {

        console.log("query devices ")

        if (error)
            setSkipLoginScreen(false)


        if (isflutter) {
            if (!window.flutter_inappwebview.callHandler) {
                return window.flutter_inappwebview._callHandler('checkauthlogin', [username, password])
                    .then(function (result) {
                        console.log("user: " + JSON.stringify(result))
                        if (result?.username !== "" && result?.password) {
                            setUsername(result?.username)
                            setPassword(result?.password)
                            setRememberme(true)
                            handleLogin(result?.username, result?.password)
                        }
                        else {

                            setSkipLoginScreen(false)

                        }

                    }).catch(err => setSkipLoginScreen(false))
            } else {
                return window.flutter_inappwebview.callHandler('checkauthlogin', [username, password])
                    .then(function (result) {
                        console.log("user: " + JSON.stringify(result))
                        if (result?.username !== "" && result?.password) {
                            setUsername(result?.username)
                            setPassword(result?.password)
                            setRememberme(true)
                            handleLogin(result?.username, result?.password)
                        } else {

                            setSkipLoginScreen(false)
                        }
                    }).catch(err => setSkipLoginScreen(false))
            }
        }


    }, [])

    useEffect(() => {
        if (skipLoginScreen) {
            const timer = setTimeout(() => {
                setSkipLoginScreen(false);

            }, 5000);
            return () => clearTimeout(timer); // Cleanup timeout on unmount
        }

    }, [])


    const handleLogin = (un, pw) => {
        setError(false)
        passwordCredentials(un, pw, nextStep, setError).then(x => {

            if (rememberme) {
                if (isflutter) {
                    if (!window.flutter_inappwebview.callHandler) {
                        console.log("_callhandler");
                        return window.flutter_inappwebview._callHandler('loginauth', ...[un, pw])
                            .then(function (result) {
                            })
                    } else {
                        console.log("callhandler");
                        return window.flutter_inappwebview.callHandler('loginauth', ...[un, pw])
                            .then(function (result) {
                            })
                    }
                }
            }
        }).catch(err => setError(true))
    }

    const handleLogOut = (event) => {
        logout()
        history.push("/")
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleChangeRememberMe = (e) => {
        setRememberme(e.target.checked)
    }

    if (skipLoginScreen)
        return <Box
            sx={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 1300, // Ensures it's above other elements
            }}
        >
            <Box
                component="img"
                src="/nextstep-2.png" // Replace with your PNG
                alt="Loading..."
                sx={{
                    width: "200px", // Full width

                }}
            />
        </Box>


    return (
        <Grid item style={{ borderRadius: "10px", padding: "15px" }}>
            <Card style={{ borderRadius: "10px", padding: "15px" }}>
                <div className={classes.borderColor} >
                    <Grid container justifyContent="flex-start" alignContent="flex-start" alignItems="flex-start">
                        <Grid item xs>
                            <Typography variant="h3" align="center"> {headerText} </Typography>
                        </Grid>
                    </Grid>
                    <form className={classes.root} noValidate autoComplete="off">


                        <Grid container direction="column" justifyContent="flex-start" alignContent="center"
                            alignItems="center">
                            <TextField
                                className={classes.instructions}
                                value={username}
                                onChange={e => setUsername(e.target.value)}
                                variant="outlined"
                                required
                                id="standard-required"
                                label="Email"
                            />

                            <FormControl sx={{ marginTop: 1, width: "100%" }} variant="outlined">
                                <InputLabel style={{ marginTop: 7 }} htmlFor="gspassword">Password</InputLabel>
                                <OutlinedInput
                                    className={classes.instructions}
                                    onChange={e => setPassword(e.target.value)}
                                    value={password}
                                    required
                                    type={showPassword ? 'text' : 'password'}
                                    variant="outlined"
                                    id="gspassword"
                                    label="Password"
                                    autoComplete="current-password"
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    onKeyDown={e => {
                                        if (e.key === "Enter") {
                                            handleLogin(username, password)
                                        }
                                    }}
                                />
                            </FormControl>
                            {isflutter ?
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox checked={rememberme} onChange={handleChangeRememberMe} />}
                                        label="Login merken" />
                                </FormGroup> : <></>
                            }


                            {error ? <Alert severity="error">Benutzername oder Passwort falsch</Alert> : <div />}
                            <Grid container direction="column" justifyContent="center" alignContent="stretch"
                                alignItems="stretch">

                                <Button
                                    className={classes.instructions}
                                    disabled={username === "" || password === ""}
                                    onClick={e => handleLogin(username, password)}
                                    variant="contained"
                                    color="primary"
                                    endIcon={<Send />}> Login </Button>

                                {/* {!session ? <Link to={"/register"} style={{textDecoration: "none"}}>
                            <Button startIcon={<ArrowBack/>} variant="contained"
                            
                                    className={classes.instructions}>Zur Registrierung</Button>

                        </Link> : <Button startIcon={<LockOpen/>} variant="outlined" onClick={handleLogOut}
                                          className={classes.instructions}>Ausloggen</Button>} */}

                                <Grid item xs={12}>
                                    <a href={APIURL().forgetPassword}>
                                        <Button
                                            className={classes.instructions}>Passwort zurücksetzen</Button>
                                    </a>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </Card>
        </Grid>
    );

}


